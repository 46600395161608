// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-md-blog-template-js": () => import("./../../../src/components/mdBlogTemplate.js" /* webpackChunkName: "component---src-components-md-blog-template-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-articles-axioms-js": () => import("./../../../src/pages/articles/axioms.js" /* webpackChunkName: "component---src-pages-articles-axioms-js" */),
  "component---src-pages-articles-good-craftsman-never-blames-tools-js": () => import("./../../../src/pages/articles/good-craftsman-never-blames-tools.js" /* webpackChunkName: "component---src-pages-articles-good-craftsman-never-blames-tools-js" */),
  "component---src-pages-articles-the-tyranny-of-the-new-keyword-1-js": () => import("./../../../src/pages/articles/the-tyranny-of-the-new-keyword-1.js" /* webpackChunkName: "component---src-pages-articles-the-tyranny-of-the-new-keyword-1-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */)
}

